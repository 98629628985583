exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-wasm-algorithms-index-tsx": () => import("./../../../src/pages/wasm/algorithms/index.tsx" /* webpackChunkName: "component---src-pages-wasm-algorithms-index-tsx" */),
  "component---src-pages-wasm-algorithms-is-prime-tsx": () => import("./../../../src/pages/wasm/algorithms/isPrime.tsx" /* webpackChunkName: "component---src-pages-wasm-algorithms-is-prime-tsx" */),
  "component---src-pages-wasm-algorithms-prime-factorization-tsx": () => import("./../../../src/pages/wasm/algorithms/primeFactorization.tsx" /* webpackChunkName: "component---src-pages-wasm-algorithms-prime-factorization-tsx" */),
  "component---src-pages-wasm-animations-index-tsx": () => import("./../../../src/pages/wasm/animations/index.tsx" /* webpackChunkName: "component---src-pages-wasm-animations-index-tsx" */),
  "component---src-pages-wasm-animations-lifegame-tsx": () => import("./../../../src/pages/wasm/animations/lifegame.tsx" /* webpackChunkName: "component---src-pages-wasm-animations-lifegame-tsx" */),
  "component---src-pages-wasm-index-tsx": () => import("./../../../src/pages/wasm/index.tsx" /* webpackChunkName: "component---src-pages-wasm-index-tsx" */)
}

